import {
  DataGrid,
  GridRenderCellParams,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button, Card } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { fetchAddons, deleteAddon } from "../../../services/addonService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import NewAddonModal from "./NewAddonModal";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const AddonsView: React.FC = () => {
  const { isLoading, addons } = useAppSelector((state) => state.addonReducer);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAddon, setSelectedAddon] = useState<Addon | null>(null);

  const columns: GridColDef[] = [
    { field: "plate", minWidth: 150, flex: 1, headerName: "Patente" },

    {
      field: "edit",
      minWidth: 100,
      flex: 1,
      headerName: "Editar",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "blue", color: "white" }}
          onClick={() => {
            for (let i = 0; i < addons.length; i++) {
              if (addons[i]._id === params.row._id) {
                setSelectedAddon(addons[i]);
                setModalOpen(true);
              }
            }
          }}
        >
          {" "}
          Editar
        </Button>
      ),
    },
    {
      field: "delete",
      minWidth: 150,
      flex: 1,
      headerName: "Borrar",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "red", color: "white" }}
          onClick={() => {
            deleteAddon(params.row._id);
          }}
        >
          Borrar
        </Button>
      ),
    },
  ];
  useEffect(() => {
    fetchAddons();
  }, []);

  return (
    <div className="p-5 h-full overflow-auto">
      <div className="text-xl mb-5">Semi Remolques</div>
      <div className="mb-3 flex justify-end pr-7">
        <Button variant="contained" onClick={() => setModalOpen(true)}>
          {" "}
          Agregar
        </Button>
      </div>
      <div>
        {isLoading ? (
          <div className="flex justify-center w-full col-span-full">
            <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
          </div>
        ) : (
          <Card>
            <DataGrid
              sx={{ padding: 2 }}
              autoHeight={true}
              columns={columns}
              rows={addons}
              pageSize={20}
              getRowId={(row) => row._id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
            />
            <NewAddonModal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              addon={selectedAddon}
              setAddon={setSelectedAddon}
            />
          </Card>
        )}
      </div>
    </div>
  );
};

export default AddonsView;
