import axios from "./client"
import { TripActions } from "../reducers/TripReducer"
import store from "./../store"
const API_URL = process.env.REACT_APP_API_URL

export const fetchTrips = async () => {
    store.dispatch({ type: TripActions.RequestTrips, payload: {} })
    const { data: trips } = await axios.get<Trip[]>(`${API_URL}trips`)
    store.dispatch({
        type: TripActions.ReceiveTrips,
        payload: trips,
    })
    }
export const addTrip = async (trip: Partial<Trip>) => {
    const { data: newTrip } = await axios.post<Trip>(`${API_URL}trips`, trip)
    store.dispatch({
        type: TripActions.AddTrip,
        payload: newTrip,
    })
    }
export const updateTrip = async (id: string, trip: Partial<Trip>) => {
    const { data: editedTrip } = await axios.patch<Trip>(
        `${API_URL}trips/${id}`,
        trip
    )
    store.dispatch({
        type: TripActions.AddTrip,
        payload: editedTrip,
    })
    return editedTrip
    }

export const generateTripPDF = (id: string) => {
        axios
          .get(API_URL + `trips/pdf/${id}`, {
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          });
      };