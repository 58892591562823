import axios from "./client";
import { SpotActions } from "../reducers/SpotReducer";
import store from "../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchSpots = async () => {
    store.dispatch({ type: SpotActions.RequestSpots, payload: {} });
    const { data: spots } = await axios.get<Spot[]>(`${API_URL}spots`);
    store.dispatch({
        type: SpotActions.ReceiveSpots,
        payload: spots,
    });
    }
export const deleteSpot = async (id: string) => {
    const { data: spot } = await axios.delete<Spot>(`${API_URL}spots/${id}`);
    store.dispatch({
        type: SpotActions.DeleteSpot,
        payload: spot,
    });
    }
export const updateSpot = async (id: string, spot: Partial<Spot>) => {
    const { data: editedSpot } = await axios.patch<Spot>(
        `${API_URL}spots/${id}`,
        spot
        );
    store.dispatch({
        type: SpotActions.AddSpot,
        payload: editedSpot,
        });
    }
export const addSpot = async (spot: Partial<Spot>) => {
    const { data: newSpot } = await axios.post<Spot>(
        `${API_URL}spots`,
        spot
        );
    store.dispatch({
        type: SpotActions.AddSpot,
        payload: newSpot,
        });
    }
