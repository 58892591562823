export const filterTripsForView = (
  trips: Trip[],
  fromDate: Date,
  toDate: Date,
  user: User
) => {
  if (user?.enterprise.adminDecidesTrips) {
    fromDate!.setHours(0, 0, 0, 0);
    toDate!.setHours(23, 59, 59, 999);
    var filteredTripsToSend = trips.filter((trip) => {
      const time = new Date(trip.programmedAt!).getTime();
      const sd = fromDate!.getTime();
      const ed = toDate!.getTime();
      return sd <= time && time <= ed;
    });
    if (user?.role === "customer") {
      if (user?.viewsAllowed.includes("Cortesia")) {
        filteredTripsToSend = filteredTripsToSend.filter((trip) => {
          return trip.paymentType === "Cortesia";
        });
      }
      if (user?.viewsAllowed.includes("Corporativo")) {
        filteredTripsToSend = filteredTripsToSend.filter((trip) => {
          return trip.paymentType === "Corporativo";
        });
      }
    }
    return filteredTripsToSend;
  } else {
    fromDate!.setHours(0, 0, 0, 0);
    toDate!.setHours(23, 59, 59, 999);
    const filteredTripsToSend = trips.filter((trip) => {
      const time = new Date(trip.programmedAt!).getTime();
      const sd = fromDate!.getTime();
      const ed = toDate!.getTime();
      return sd <= time && time <= ed;
    });
    return filteredTripsToSend;
  }
};

export const initialFilter = (trips: Trip[], user: User) => {
  if (user.role === "customer") {
    if (user.viewsAllowed.includes("Cortesia")) {
      return trips.filter((trip) => trip.paymentType === "Cortesia");
    }
    if (user.viewsAllowed.includes("Corporativo")) {
      return trips.filter((trip) => trip.paymentType === "Corporativo");
    }
  } else {
    return trips;
  }
};
