import { combineReducers } from "redux"
import authReducer from "./AuthReducer"
import driverReducer from "./DriverReducer"
import vehicleReducer from "./VehicleReducer"
import spotReducer from "./SpotReducer"
import passengerReducer from "./PassengerReducer"
import customerReducer from "./CustomerReducer"
import tripReducer from "./TripReducer"
import addonReducer from "./AddonReducer"

const rootReducer = combineReducers({
  authReducer,
  driverReducer,
  vehicleReducer,
  spotReducer,
  passengerReducer,
  customerReducer,
  tripReducer,
  addonReducer

})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
