import {
  DataGrid,
  GridRenderCellParams,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridRowModel,
} from "@mui/x-data-grid";
import {
  Alert,
  AlertProps,
  Button,
  Card,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { fetchTrips, generateTripPDF, updateTrip } from "../../../services/tripService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import DocumentsModal from "./DocumentsModal";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fetchDrivers, sendMessage } from "../../../services/driverService";
import EditableSelectLabel from "../../EditableSelectLabel";
import { fetchVehicles } from "../../../services/vehicleService";
import { filterTripsForView, initialFilter } from "../../../utils/filterTrips";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};
const TripsView: React.FC = () => {
  const { isLoading, trips } = useAppSelector(
    (state: any) => state.tripReducer
  );
  const user = useAppSelector((s) => s.authReducer.user);
  const isTripEditable =
    user?.enterprise.adminDecidesTrips && user.enterprise.managesPrice;
  const [modalOpen, setModalOpen] = useState(false); // para abrir los documentos
  const drivers = useAppSelector((s) => s.driverReducer.drivers);
  const vehicles = useAppSelector((s) => s.vehicleReducer.vehicles);
  const isLoadingDrivers = useAppSelector((s) => s.driverReducer.isLoading);
  const [selectedPhotos, setSelectedPhotos] = useState<string[] | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(new Date(Date.now()));
  const [toDate, setToDate] = useState<Date | null>(new Date(Date.now()));
  const [filteredTrips, setFilteredTrips] = useState<Trip[]>([]);

  const filterTrips = () => {
    const filteredTrips = filterTripsForView(trips, fromDate!, toDate!, user!);
    setFilteredTrips(filteredTrips);
  };
  const processRowUpdate = useCallback(async (newRow: GridRowModel) => {
    const response = await updateTrip(newRow._id, {
      origin: newRow.origin,
      destination: newRow.destination,
      price: newRow.price,
      correlative: newRow.correlative,
      paymentType: newRow.paymentType,
      observations: newRow.observations,
      passengerName: newRow.passengerName,
      passengerQty: newRow.passengerQty,
      programmedAt: newRow.programmedAt,
      driver: newRow.driver,
      vehiclePlate: newRow.vehiclePlate,
      tripType: newRow.tripType,
      carType: newRow.carType,
      serviceType: newRow.serviceType,
    });
    setSnackbar({ children: "Viaje actualizado", severity: "success" });
    return response;
  }, []);
  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  var columns: GridColDef[] = [
    {
      field: "driver",
      minWidth: 250,
      flex: 1,
      headerName: "Nombre Conductor",
      valueGetter(params) {
        if (
          typeof params.row.driver !== "undefined" &&
          params.row.driver !== null
        ) {
          return params.row.driver.name;
        } else {
          return "Conductor Eliminado";
        }
      },
      valueFormatter(params) {
        return params.value;
      },

      renderCell: (params: GridRenderCellParams) => {
        if (params.row.driver === null) {
          return <span>Conductor eliminado</span>;
        } else if (isTripEditable) {
          return (
            <EditableSelectLabel
              value={params.row.driver}
              label="Conductor"
              labelValue={params.row.driver.name}
              onSubmit={async (newValue) => {
                const newDriver = drivers.find((d) => d._id === newValue);
                if (newDriver) {
                  console.log(newDriver);
                  const vehicleToSend = vehicles.find(
                    (v) => v.driver._id === newDriver._id
                  );
                  console.log(vehicleToSend);

                  processRowUpdate({
                    ...params.row,
                    driver: newDriver,
                    vehiclePlate: vehicleToSend?.plate,
                  });
                }
              }}
              id="driver"
            >
              {drivers.map((d) => (
                <MenuItem key={d._id} value={d._id}>
                  {d.name}
                </MenuItem>
              ))}
            </EditableSelectLabel>
          );
        } else {
          return <span>{params.row.driver.name}</span>;
        }
      },
    },
    {
      field: "origin",
      minWidth: 150,
      flex: 1,
      headerName: "Origen",
      editable: isTripEditable!,
    },
    {
      field: "destination",
      minWidth: 150,
      flex: 1,
      headerName: "Destino",
      editable: isTripEditable!,
    },
    { field: "vehiclePlate", minWidth: 150, flex: 1, headerName: "Patente" },
  ];
  const priceColumn: GridColDef[] = [
    {
      field: "startedAt",
      minWidth: 150,
      flex: 1,
      headerName: "Hora Inicio",
      valueFormatter(params) {
        if (typeof params.value === "undefined") {
          return "";
        } else {
          const date = new Date(params.value);
          const minutes = date.getMinutes();

          const hours = date.getHours();
          return `${hours}:${minutes.toString().padStart(2, "0")}`;
        }
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = new Date(params.row.startedAt);
        const minutes = date.getMinutes();

        const hours = date.getHours();
        if (typeof params.row.startedAt === "undefined") {
          return <span></span>;
        } else
          return (
            <span>{`${hours}:${minutes.toString().padStart(2, "0")}`}</span>
          );
      },
    },
    {
      field: "finishedAt",
      minWidth: 150,
      flex: 1,
      headerName: "Hora Fin",
      valueFormatter(params) {
        if (typeof params.value === "undefined") {
          return "";
        } else {
          const date = new Date(params.value);
          const minutes = date.getMinutes();

          const hours = date.getHours();
          return `${hours}:${minutes.toString().padStart(2, "0")}`;
        }
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = new Date(params.row.finishedAt);
        const minutes = date.getMinutes();

        const hours = date.getHours();
        if (typeof params.row.finishedAt === "undefined") {
          return <span></span>;
        } else
          return (
            <span>{`${hours}:${minutes.toString().padStart(2, "0")}`}</span>
          );
      },
    },
    {
      field: "status",
      minWidth: 150,
      flex: 1,
      headerName: "Estado",
      valueGetter(params) {
        if (typeof params.row.finishedAt === "undefined") {
          return "Pendiente";
        } else {
          return "Finalizado";
        }
      },
      valueFormatter(params) {
        return params.value;
      },
      renderCell: (params: GridRenderCellParams) => {
        if (typeof params.row.finishedAt === "undefined") {
          return <span style={{ color: "red" }}>Pendiente</span>;
        } else return <span style={{ color: "green" }}>Completado</span>;
      },
    },
    {
      field: "tripType",
      minWidth: 200,
      flex: 1,
      headerName: "Tipo de Viaje",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <EditableSelectLabel
            id="tripType"
            label=""
            value={params.row.tripType}
            labelValue={params.row.tripType}
            onSubmit={async (tripType) => {
              processRowUpdate({ ...params.row, tripType });
            }}
          >
            <MenuItem value={"Programado"}>Programado</MenuItem>
            <MenuItem value={"De Momento"}>De Momento</MenuItem>
          </EditableSelectLabel>
        );
      },
    },
    {
      field: "carType",
      minWidth: 200,
      flex: 1,
      headerName: "Tipo de Movil",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <EditableSelectLabel
            id="carType"
            label=""
            value={params.row.carType}
            labelValue={params.row.carType}
            onSubmit={async (carType) => {
              processRowUpdate({ ...params.row, carType });
            }}
          >
            <MenuItem value={"Movil Base"}>Movil Base</MenuItem>
            <MenuItem value={"Movil Apoyo"}>Movil Apoyo</MenuItem>
          </EditableSelectLabel>
        );
      },
    },
    {
      field: "serviceType",
      minWidth: 200,
      flex: 1,
      headerName: "Tipo de Traslado",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <EditableSelectLabel
            id="serviceType"
            label=""
            value={params.row.serviceType}
            labelValue={params.row.serviceType}
            onSubmit={async (serviceType) => {
              processRowUpdate({ ...params.row, serviceType });
            }}
          >
            <MenuItem value={"Ida"}>Ida</MenuItem>
            <MenuItem value={"Ida y vuelta"}>Ida y vuelta</MenuItem>
            <MenuItem value={"Presentacion"}>Presentacion</MenuItem>
            <MenuItem value={"Disposicion"}>Disposicion</MenuItem>
            <MenuItem value={"Cancelado"}>Cancelado</MenuItem>
          </EditableSelectLabel>
        );
      },
    },
    {
      field: "price",
      minWidth: 150,
      flex: 1,
      headerName: "Precio",
      editable: isTripEditable!,
    },
    {
      field: "passengerQty",
      minWidth: 150,
      flex: 1,
      headerName: "Cantidad Pasajeros",
      editable: isTripEditable!,
    },
    {
      field: "passengerName",
      minWidth: 150,
      flex: 1,
      headerName: "Nombre Pasajero",
      editable: isTripEditable!,
    },
    {
      field: "correlative",
      minWidth: 150,
      flex: 1,
      headerName: "Correlativo",
      editable: isTripEditable!,
    },
    {
      field: "paymentType",
      minWidth: 200,
      flex: 1,
      headerName: "Forma de Pago",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <EditableSelectLabel
            id="paymentType"
            label=""
            value={params.row.paymentType}
            labelValue={params.row.paymentType}
            onSubmit={async (paymentType) => {
              processRowUpdate({ ...params.row, paymentType });
            }}
          >
            <MenuItem value={"Efectivo"}>Efectivo</MenuItem>
            <MenuItem value={"Cortesia"}>Cortesia</MenuItem>
            <MenuItem value={"Corporativo"}>Corporativo</MenuItem>
            <MenuItem value={"Transbank"}>Transbank</MenuItem>
            <MenuItem value={"Transferencia"}>Transferencia</MenuItem>
          </EditableSelectLabel>
        );
      },
    },
  ];
  const reminderColumn: GridColDef[] = [
    {
      field: "reminder",
      minWidth: 150,
      flex: 1,
      headerName: "Recordatorio",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          sx={{ backgroundColor: "blue", color: "white" }}
          onClick={() => {
            sendMessage(params.row.driver._id, params.row.destination);
          }}
        >
          Enviar
        </Button>
      ),
    },
  ];
  const customerAndDateColumn: GridColDef[] = [
    {
      field: "addonPlate",
      minWidth: 150,
      flex: 1,
      headerName: "Semi Remolque",
      renderCell: (params: GridRenderCellParams) => {
        if (
          params.row.addonPlate !== null &&
          typeof params.row.addonPlate !== "undefined"
        ) {
          return <span>{params.row.addonPlate}</span>;
        } else return <span></span>;
      },
    },
    {
      field: "customer",
      minWidth: 150,
      flex: 1,
      headerName: "Cliente",
      valueGetter(params) {
        if (typeof params.row.customer !== "undefined") {
          if (params.row.customer !== null) {
            if (
              params.row.customer.branch !== null &&
              typeof params.row.customer.branch !== "undefined"
            ) {
              return `${params.row.customer.name} - ${params.row.customer.branch}`;
            } else {
              return params.row.customer.name;
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      valueFormatter(params) {
        return params.value;
      },
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.customer !== undefined && params.row.customer !== null)
          return (
            <span>
              {params.row.customer.branch !== null &&
                typeof params.row.customer.branch !== "undefined"
                ? `${params.row.customer.name} - ${params.row.customer.branch}`
                : params.row.customer.name}
            </span>
          );
        else return <span>Cliente eliminado</span>;
      },
    },
  ];
  const programmedDateColumn: GridColDef[] = [
    {
      field: "programmedAt",
      minWidth: 150,
      flex: 1,
      headerName: "Fecha Programada",
      editable: isTripEditable!,
      type: "dateTime",
      valueFormatter(params) {
        const date = new Date(params.value);
        return `${date.getDate()}/${date.getMonth() + 1
          }/${date.getFullYear()} `;
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = new Date(params.row.programmedAt);
        return (
          <span>{`${date.getDate()}/${date.getMonth() + 1
            }/${date.getFullYear()} `}</span>
        );
      },
    },
    {
      field: "programmedHourAt",
      minWidth: 150,
      flex: 1,
      headerName: "Hora Programada",
      valueGetter(params) {
        const date = new Date(params.row.programmedAt);
        const minutes = date.getMinutes();
        const hours = date.getHours();
        return `${hours}:${minutes.toString().padStart(2, "0")}`;
      },

      renderCell: (params: GridRenderCellParams) => {
        const date = new Date(params.row.programmedAt);
        const minutes = date.getMinutes();

        return (
          <span>{`${date.getHours()}:${minutes
            .toString()
            .padStart(2, "0")}`}</span>
        );
      },
    },
  ];

  const documentColumn: GridColDef[] = [
    {
      field: "document",
      minWidth: 150,
      flex: 1,
      headerName: "Documento",
      renderCell: (params: GridRenderCellParams) => {
        if (
          typeof params.row.photos !== "undefined" &&
          params.row.photos.length > 0
        ) {
          return (
            <Button
              variant="contained"
              onClick={() => {
                setSelectedPhotos(params.row.photos);
                setModalOpen(true);
              }}
            >
              Abrir
            </Button>
          );
        } else return <span></span>;
      },
    },
    {
      field: "pdfDownload",
      minWidth: 150,
      flex: 1,
      headerName: "Descargar",
      renderCell: (params: GridRenderCellParams) => {
        if (
          typeof params.row.photos !== "undefined" &&
          params.row.photos.length > 0
        ) {
          return (
            <Button
              variant="contained"
              onClick={() => {
                generateTripPDF(params.row._id);
              }}
            >
              Descargar
            </Button>
          );
        } else return <span></span>;
      },
    }
  ];
  const observationsColumn: GridColDef[] = [
    {
      field: "observations",
      minWidth: 150,
      flex: 1,
      headerName: "Observaciones",
      editable: true,
    },
  ];

  if (user?.enterprise.managesPrice && user?.role === "manager") {
    columns = [
      ...columns,
      ...priceColumn,
      ...observationsColumn,
      ...reminderColumn,
    ];
    columns.splice(4, 0, ...programmedDateColumn);
  }
  if (user?.enterprise.managesCustomers) {
    columns = [
      ...columns,
      ...programmedDateColumn,
      ...documentColumn,
      ...observationsColumn,
    ];
    columns.splice(4, 0, ...customerAndDateColumn);
  }
  if (user?.role === "customer") {
    columns = [...columns, ...priceColumn, ...observationsColumn];
    columns.splice(4, 0, ...programmedDateColumn);
  }

  useEffect(() => {
    fetchDrivers();
    fetchTrips();
    fetchVehicles();
  }, []);
  useEffect(
    () => setFilteredTrips(initialFilter(trips, user!)!),
    [trips, user]
  );

  return (
    <div className="p-5 h-full overflow-auto">
      <div className="text-xl mb-5">Trayectos </div>
      <div className="flex justify-end gap-2 m-3">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Desde"
            inputFormat="dd/MM/yyyy"
            value={fromDate}
            onChange={(newValue: Date | null) => {
              setFromDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Hasta"
            inputFormat="dd/MM/yyyy"
            value={toDate}
            onChange={(newValue: Date | null) => {
              setToDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Button
          variant="contained"
          disabled={!fromDate || !toDate}
          color="primary"
          onClick={() => {
            filterTrips();
          }}
        >
          Filtrar
        </Button>
      </div>
      <div>
        {isLoading || isLoadingDrivers ? (
          <div className="flex justify-center w-full col-span-full">
            <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
          </div>
        ) : (
          <Card>
            <DataGrid
              sx={{ padding: 2 }}
              autoHeight={true}
              columns={columns}
              rows={filteredTrips}
              pageSize={20}
              getRowId={(row) => row._id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              processRowUpdate={processRowUpdate}
              experimentalFeatures={{ newEditingApi: true }}
              onProcessRowUpdateError={handleProcessRowUpdateError}
            />
          </Card>
        )}
      </div>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <DocumentsModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        photos={selectedPhotos}
      />
    </div>
  );
};
export default TripsView;
