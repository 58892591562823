interface State {
    spots: Spot[]
    isLoading: boolean
}
const initialState: State = {
    spots: [],
    isLoading: false,
}
export enum SpotActions {
    RequestSpots = 'REQUEST_SPOTS',
    ReceiveSpots = 'RECEIVE_SPOTS',
    AddSpot = 'ADD_SPOT',
    DeleteSpot = 'DELETE_SPOT',
}
const reducer = (
    state: State = initialState,
    { type, payload }: { type: SpotActions; payload: any }
): State => {
    switch (type) {
        case SpotActions.RequestSpots: //hago el llamado para que la api me traiga los producots
            return { ...state, isLoading: true }
        case SpotActions.ReceiveSpots: //recibo el arreglo de productos
            return { ...state, spots: payload, isLoading: false }
        case SpotActions.AddSpot: //agrego un producto
            return {
                ...state,
                spots: [
                    ...state.spots.filter((p) => p._id !== payload._id),
                    payload,
                ],
            }
        case SpotActions.DeleteSpot:
            return {
                ...state,
                spots: state.spots.filter((p) => p._id !== payload._id),
            }
        default:
            return state
    }
}
export default reducer