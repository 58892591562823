import axios from "./client";
import { PassengerActions } from "../reducers/PassengerReducer";
import store from "../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchPassengers = async () => {
    store.dispatch({ type: PassengerActions.RequestPassengers, payload: {} });
    const { data: passengers } = await axios.get<Passenger[]>(
        `${API_URL}passengers`
    );
    store.dispatch({
        type: PassengerActions.ReceivePassengers,
        payload: passengers,
    });
    }
export const deletePassenger = async (id: string) => {
    const { data: passenger } = await axios.delete<Passenger>(
        `${API_URL}passengers/${id}`
    );
    store.dispatch({
        type: PassengerActions.DeletePassenger,
        payload: passenger,
    });
    }
export const updatePassenger = async (id: string, passenger: Partial<Passenger>) => {
    const { data: editedPassenger } = await axios.patch<Passenger>(
        `${API_URL}passengers/${id}`,
        passenger
    );
    store.dispatch({
        type: PassengerActions.AddPassenger,
        payload: editedPassenger,
    });
    }
export const addPassenger = async (passenger: Partial<Passenger>) => {
    const { data: newPassenger } = await axios.post<Passenger>(
        `${API_URL}passengers`,
        passenger
    );
    store.dispatch({
        type: PassengerActions.AddPassenger,
        payload: newPassenger,
    });
    }