import axios from "./client";
import { AddonActions } from "../reducers/AddonReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchAddons = async () => {
    store.dispatch({ type: AddonActions.RequestAddons, payload: {} });
    const { data: addons } = await axios.get<Addon[]>(`${API_URL}addons`);
    store.dispatch({
        type: AddonActions.ReceiveAddons,
        payload: addons,
    });
}
export const addAddon = async (addon: Partial<Addon>) => {
    const { data: newAddon } = await axios.post<Addon>(`${API_URL}addons`, addon);
    store.dispatch({
        type: AddonActions.AddAddon,
        payload: newAddon,
    });
}
export const deleteAddon = async (id: string) => {
    const { data: addon } = await axios.delete<Addon>(`${API_URL}addons/${id}`);
    store.dispatch({
        type: AddonActions.DeleteAddon,
        payload: addon,
    });
}
export const updateAddon = async (id: string, addon: Partial<Addon>) => {
    const { data: editedAddon } = await axios.patch<Addon>(
        `${API_URL}addons/${id}`,
        addon
    );
    store.dispatch({
        type: AddonActions.AddAddon,
        payload: editedAddon,
    });
}
