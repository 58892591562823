import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect } from "react";

import { useForm } from "../../../hooks/useForm";
import { addAddon, updateAddon } from "../../../services/addonService";

interface Props {
  open: boolean;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  addon: Addon | null;
  setAddon: (addon: Addon | null) => void;
}

const initialValues = {
  plate: "",
};

const NewAddonModal: React.FC<Props> = ({ open, onClose, addon, setAddon }) => {
  const [values, setValues, handleChange] = useForm(initialValues);

  useEffect(() => {
    if (addon !== null) {
      setValues({ ...values, plate: addon.plate });
    }
  }, [addon]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onTransitionEnd={() => !open && setValues(initialValues)}
    >
      <DialogTitle>Nuevo Semi Remolque</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2 mt-5">
          <TextField
            onChange={handleChange}
            id="plate"
            label="Placa"
            value={values.plate}
          ></TextField>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (addon !== null) {
              setAddon(null);
              setValues(initialValues);
            }
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          disabled={values.plate === ""}
          onClick={() => {
            if (addon !== null) {
              updateAddon(addon._id, values);
              setAddon(null);
              setValues(initialValues);
            } else {
              addAddon(values);
              setAddon(null);
              setValues(initialValues);
            }
            onClose();
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewAddonModal;
