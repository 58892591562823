interface State {
    addons: Addon[]
    isLoading: boolean
}
const initialState: State = {
    addons: [],
    isLoading: false,
}
export enum AddonActions {
    RequestAddons = 'REQUEST_ADDONS',
    ReceiveAddons = 'RECEIVE_ADDONS',
    AddAddon = 'ADD_ADDON',
    DeleteAddon = 'DELETE_ADDON',
}
const reducer = (
    state: State = initialState,
    { type, payload }: { type: AddonActions; payload: any }
): State => {
    switch (type) {
        case AddonActions.RequestAddons:
            return { ...state, isLoading: true }
        case AddonActions.ReceiveAddons:
            return { ...state, addons: payload, isLoading: false }
        case AddonActions.AddAddon:
            return {
                ...state,
                addons: [
                    ...state.addons.filter((p) => p._id !== payload._id),
                    payload,
                ],
            }
        case AddonActions.DeleteAddon:
            return {
                ...state,
                addons: state.addons.filter((p) => p._id !== payload._id),
            }
        default:
            return state
    }
}
export default reducer