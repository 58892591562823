interface State {
    passengers: Passenger[]
    isLoading: boolean
}

const initialState: State = {
    passengers: [],
    isLoading: false,
}

export enum PassengerActions {
    RequestPassengers = 'REQUEST_PASSENGERS',
    ReceivePassengers = 'RECEIVE_PASSENGERS',
    AddPassenger = 'ADD_PASSENGER',
    DeletePassenger = 'DELETE_PASSENGER',
}

const reducer = (
    state: State = initialState,
    { type, payload }: { type: PassengerActions; payload: any }
): State => {
    switch (type) {
        case PassengerActions.RequestPassengers:
            return { ...state, isLoading: true }
        case PassengerActions.ReceivePassengers: 
            return { ...state, passengers: payload, isLoading: false }
        case PassengerActions.AddPassenger: //agrego un producto
            return {
                ...state,
                passengers: [
                    ...state.passengers.filter((p) => p._id !== payload._id),
                    payload,
                ],
            }
        case PassengerActions.DeletePassenger:
            return {
                ...state,
                passengers: state.passengers.filter((p) => p._id !== payload._id),
            }
        default:
            return state
    }
}

export default reducer