import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
  } from "@mui/material"
  import { useForm } from "../../../hooks/useForm"
  import { validateRUT } from 'validar-rut'
import { useEffect } from "react"
import { addPassenger, updatePassenger } from "../../../services/passengerService"


interface Props {
    open: boolean
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void
    passenger: Passenger | null
    setPassenger: (passenger: Passenger | null) => void
  }
  
  const NewPassengersModal: React.FC<Props> = ({ open, onClose, passenger, setPassenger }) => {

    const initialValues = {
        name: "",
        rut: ""
      }
      const [values, setValues, handleChange] = useForm(initialValues)
      useEffect(() => {
        if(passenger){
            setValues({...values, name: passenger.name, rut: passenger.rut})
        }
    }, [passenger, open])
    return (
      <Dialog
        open={open}
        onClose={onClose}
        onTransitionEnd={() => !open && setValues(initialValues)}
        >
        <DialogTitle>Nuevo Pasajero</DialogTitle>
        <DialogContent>
            <div className="flex flex-col gap-2 mt-5">
                <TextField onChange={handleChange} disabled={!!passenger} id="rut" label="Rut" value={values.rut}></TextField>
                <TextField
                    onChange={handleChange}
                    id="name"
                    label="Nombre"
                    value={values.name}
                ></TextField>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                if(passenger!== null){
                    setPassenger(null)
                    setValues(initialValues)
                }
                onClose()
    }}>Cancelar</Button>
            <Button variant={"contained"} onClick={ async () => {
                const rutValido = validateRUT(values.rut)
                if(passenger !== null){
                    await updatePassenger(passenger._id, values)
                    setPassenger(null)
                    setValues(initialValues)
                }
                else if(rutValido){
                    await addPassenger(values)
                    setPassenger(null)
                    setValues(initialValues)
                }
                else 
                {
                    alert("Rut invalido")
                }
                onClose()}
            }>Aceptar</Button>
        </DialogActions>
        </Dialog>
    )
}
export default NewPassengersModal