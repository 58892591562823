export enum Routes {
    Home = "/",
    Drivers = "/drivers",
    Vehicles = "/vehicles",
    Spots = "/spots",
    Login = "/login",
    Passengers = "/passengers",
    Customers = "/customers",
    Trips = "/trips",
    NewTrip = "/trips/new",
    Addons = "/addons",
  }
  