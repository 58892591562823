import { Alert, Button, Card, Input, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { addTrip, fetchTrips } from "../../../services/tripService";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import { TextField, FormControl, InputLabel, MenuItem } from "@mui/material";

import { useForm } from "../../../hooks/useForm";
import { fetchDrivers, sendMessage } from "../../../services/driverService";
import { fetchVehicles } from "../../../services/vehicleService";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const NewTrip: React.FC = () => {
  const drivers = useAppSelector((s) => s.driverReducer.drivers);
  const vehicles = useAppSelector((s) => s.vehicleReducer.vehicles);
  const [tripCreated, setTripCreated] = useState(false);
  const initialValues = {
    origin: "",
    destination: "",
    price: 0,
    passengerQty: 0,
    driver: "",
    vehiclePlate: "",
    passengerName: "",
    observations: "",
    paymentType: "",
    tripType: "",
    carType: "",
    serviceType: "",
  };
  const cleanState = () => {
    setTripCreated(false);
    setValues(initialValues);
  };
  const [date, setDate] = useState<Date | null>(new Date(Date.now()));

  const handleChangeDate = (newValue: Date | null) => {
    setDate(newValue);
  };

  const [values, setValues, handleChange, handleChangeInt] =
    useForm(initialValues);
  useEffect(() => {
    fetchDrivers();
    fetchVehicles();
  }, []);
  useEffect(() => {
    if (tripCreated === true) {
      setTimeout(cleanState, 1500);
    }
  }, [tripCreated]);
  return (
    <div className="p-5 h-full overflow-auto">
      <div className="text-xl mb-5">Nuevo Trayecto </div>

      <div className="max-w-xl">
        {tripCreated && (
          <Alert
            className="mb-5 q"
            severity="success"
            onClose={() => {
              setTripCreated(false);
            }}
          >
            <strong>Trayecto Creado Correctamente</strong>
          </Alert>
        )}

        <Card>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="flex flex-col gap-3 m-5">
              <FormControl fullWidth>
                <InputLabel id="tripType-label">Tipo de Viaje</InputLabel>
                <Select
                  labelId="tripType-label"
                  id="tripType"
                  value={values.tripType}
                  label="Tipo de Viaje"
                  onChange={(e) => {
                    setValues({ ...values, tripType: e.target.value });
                  }}
                >
                  <MenuItem value={"Programado"}>Programado</MenuItem>
                  <MenuItem value={"De Momento"}>De Momento</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  onChange={handleChange}
                  id="passengerName"
                  label="Nombre Pasajero"
                  value={values.passengerName}
                ></TextField>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  onChange={handleChange}
                  id="origin"
                  label="Origen"
                  value={values.origin}
                ></TextField>
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  onChange={handleChange}
                  id="destination"
                  label="Destino"
                  value={values.destination}
                ></TextField>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  onChange={handleChangeInt}
                  id="price"
                  label="Precio"
                  value={values.price}
                ></TextField>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  onChange={handleChangeInt}
                  id="passengerQty"
                  label="Cantidad De Pasajeros"
                  value={values.passengerQty}
                ></TextField>
              </FormControl>
              <FormControl fullWidth>
                <DesktopDatePicker
                  label="Fecha del Trayecto"
                  inputFormat="dd/MM/yyyy"
                  value={date}
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
              <FormControl fullWidth>
                <TimePicker
                  label="Hora del Trayecto"
                  value={date}
                  onChange={handleChangeDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="serviceType-label">Tipo de Traslado</InputLabel>
                <Select
                  labelId="serviceType-label"
                  id="serviceType"
                  value={values.serviceType}
                  label="Tipo de Traslado"
                  onChange={(e) => {
                    setValues({ ...values, serviceType: e.target.value });
                  }}
                >
                  <MenuItem value={"Ida"}>Ida</MenuItem>
                  <MenuItem value={"Ida y vuelta"}>Ida y vuelta</MenuItem>
                  <MenuItem value={"Presentacion"}>Presentacion</MenuItem>
                  <MenuItem value={"Disposicion"}>Disposicion</MenuItem>
                  <MenuItem value={"Cancelado"}>Cancelado</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="carType-label">Tipo de Movil</InputLabel>
                <Select
                  labelId="carType-label"
                  id="carType"
                  value={values.carType}
                  label="Tipo de Movil"
                  onChange={(e) => {
                    setValues({ ...values, carType: e.target.value });
                  }}
                >
                  <MenuItem value={"Movil Base"}>Movil Base</MenuItem>
                  <MenuItem value={"Movil Apoyo"}>Movil Apoyo</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="vehicle-label">Vehiculo</InputLabel>
                <Select
                  labelId="vehicle-label"
                  id="vehicle"
                  value={values.vehiclePlate}
                  renderValue={(selectedVehicle) => selectedVehicle}
                  label="Vehiculo"
                  onChange={(e) => {
                    vehicles.forEach((v) => {
                      if (v._id === e.target.value) {
                        if (v.driver !== undefined) {
                          setValues({
                            ...values,
                            vehiclePlate: v.plate,
                            driver: v.driver._id,
                          });
                        } else
                          setValues({
                            ...values,
                            vehiclePlate: v.plate,
                            driver: "",
                          });
                      }
                    });
                  }}
                >
                  {vehicles.map((vehicle) => (
                    <MenuItem key={vehicle._id} value={vehicle._id}>
                      {vehicle.plate}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="driver-label">Conductor</InputLabel>
                <Select
                  labelId="driver-label"
                  id="driver"
                  value={values.driver}
                  label="Conductor"
                  onChange={(e) => {
                    var vehicleToSend = "";
                    vehicles.forEach((v) => {
                      if (v.driver !== null && v.driver !== undefined) {
                        console.log(v.driver);
                        if (v.driver._id === e.target.value) {
                          vehicleToSend = v.plate;
                        }
                      }
                    });
                    if (vehicleToSend !== "") {
                      console.log(vehicleToSend);
                      setValues({
                        ...values,
                        driver: e.target.value,
                        vehiclePlate: vehicleToSend,
                      });
                    } else {
                      setValues({ ...values, driver: e.target.value });
                    }
                  }}
                >
                  {drivers.map((driver) => (
                    <MenuItem key={driver._id} value={driver._id}>
                      {driver.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="paymentType-label">Forma de Pago</InputLabel>
                <Select
                  labelId="paymentType-label"
                  id="paymentType"
                  value={values.paymentType}
                  label="Forma de Pago"
                  onChange={(e) => {
                    setValues({ ...values, paymentType: e.target.value });
                  }}
                >
                  <MenuItem value={"Efectivo"}>Efectivo</MenuItem>
                  <MenuItem value={"Cortesia"}>Cortesia</MenuItem>
                  <MenuItem value={"Corporativo"}>Corporativo</MenuItem>
                  <MenuItem value={"Transbank"}>Transbank</MenuItem>
                  <MenuItem value={"Transferencia"}>Transferencia</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  onChange={handleChange}
                  id="observations"
                  label="Observaciones"
                  value={values.observations}
                ></TextField>
              </FormControl>

              <Button
                variant={"contained"}
                disabled={
                  values.origin === "" ||
                  values.destination === "" ||
                  values.price === 0 ||
                  values.vehiclePlate === "" ||
                  values.driver === "" ||
                  values.paymentType === ""
                }
                onClick={async () => {
                  // setTripCreated(true);
                  // await addTrip({ ...values, programmedAt: date! });
                  // await sendMessage(values.driver, values.destination);
                  alert("Boton deshabilitado temporalmente")
                }}
              >
                Crear
              </Button>
            </div>
          </LocalizationProvider>
        </Card>
      </div>
    </div>
  );
};
export default NewTrip;
