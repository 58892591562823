import { mdiCheck, mdiPencil, mdiSync } from "@mdi/js";
import Icon from "@mdi/react";
import { FormControl, InputLabel, Select } from "@mui/material";
import { PropsWithChildren, ReactNode, useState } from "react";
import { useForm } from "../hooks/useForm";

interface Props<T> {
  onSubmit: (value: T) => Promise<void>;
  value: T;
  labelValue?: string;
  className?: string;
  labelClassName?: string;
  id: string;
  label?: string;
  notificationMessage?: string;
  permissions?: Permissions[];
  fullwidth?: boolean;
  renderValue?: (value: T) => ReactNode;
}

const EditableSelectLabel: <T extends any = string>(
  p: PropsWithChildren<Props<T>>
) => React.ReactElement = ({
  onSubmit,
  className,
  labelClassName,
  value,
  label,
  id,
  notificationMessage,
  permissions,
  fullwidth,
  children,
  labelValue,
  renderValue,
}) => {
  const isEditing = true;
  const [isOver, setIsOver] = useState(false);
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValues, handleChange] = useForm({ [id]: value });
  const [loading, setLoading] = useState(false);

  const allowEdit = isEditing;

  return (
    <div
      className={className ?? "w-full flex items-center"}
      onMouseOver={() => !isOver && setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
      onKeyDown={(e) => {
        e.key === "Escape" && setEditing(false);
      }}
    >
      {(!allowEdit || !editing) && (
        <div className={labelClassName ?? "flex items-center"}>
          {labelValue ? labelValue : (value as any)}
        </div>
      )}
      {allowEdit && editing && (
        <form
          className="w-full"
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);

            await onSubmit(inputValue[id]);
            setLoading(false);
            setEditing(false);
          }}
        >
          <FormControl className="w-full">
            {label && <InputLabel id={"aa-label"}>{label}</InputLabel>}
            <Select
              labelId={"aa-label"}
              value={inputValue[id]}
              onChange={(event) => {
                const target = event.target as unknown as HTMLSelectElement;

                setInputValues({
                  ...inputValue,
                  [id]: target.value as unknown as any,
                });
              }}
              fullWidth
              label={label}
              renderValue={renderValue}
            >
              {children}
            </Select>
          </FormControl>
        </form>
      )}
      {allowEdit && isOver ? (
        loading ? (
          <div className="w-7 ml-2">
            <Icon
              className="w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100"
              path={mdiSync}
              spin
            ></Icon>
          </div>
        ) : (
          <div
            className="w-7 ml-2"
            onClick={async () => {
              if (editing) {
                setLoading(true);

                await onSubmit(inputValue[id]);
                setLoading(false);
                setEditing(false);
              } else {
                setEditing(true);
              }
            }}
          >
            <Icon
              className="w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100"
              path={editing ? mdiCheck : mdiPencil}
            ></Icon>
          </div>
        )
      ) : (
        <div className="w-7 ml-2 h-5"></div>
      )}
    </div>
  );
};
export default EditableSelectLabel;
