import {
    DataGrid,
    GridRenderCellParams,
    GridColDef,
    esES,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
  } from "@mui/x-data-grid"
import { Button, Card } from "@mui/material"
import { useEffect, useState } from "react"
import { useAppSelector } from "../../../hooks/storeHooks"
import { fetchSpots, deleteSpot } from "../../../services/spotService"
import { mdiCircleSlice1 } from "@mdi/js"
import Icon from "@mdi/react"
import NewSpotModal from "./NewSpotModal"

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
            </GridToolbarContainer>
          )
        }
  
  const SpotsView: React.FC = () => {
    const { isLoading, spots } = useAppSelector((state: any) => state.spotReducer)
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedSpot, setSelectedSpot] = useState<Spot | null>(null)

    const columns: GridColDef[] = [
        { field: "name", minWidth: 150, flex: 1, headerName: "Nombre" }
        ,{
            field: "edit", minWidth: 100, flex: 1, headerName: "Editar", renderCell: (params: GridRenderCellParams) => (
                <Button
                    sx={{ backgroundColor: "blue", color: "white" }}
                    onClick={() => {
                        for(let i = 0; i < spots.length; i++){
                            if(spots[i]._id === params.row._id){
                                setSelectedSpot(spots[i])
                                setModalOpen(true)
                            }
                        }
                    }
                    }
                > Editar </Button>
            )
        },{
            field: "delete", minWidth: 100, flex: 1, headerName: "Eliminar", renderCell: (params: GridRenderCellParams) => (
                <Button
                    sx={{ backgroundColor: "red", color: "white" }}
                    onClick={() => {
                        deleteSpot(params.row._id)
                    }}
                > Eliminar </Button>)
                    
        }]
        useEffect(() => {
            fetchSpots()
        },[])
    return (
        <div className="p-5 h-full overflow-auto">
          <div className="text-xl mb-5">Puntos </div>
          <div className="mb-3 flex justify-end pr-7">
          <Button variant="contained" onClick={() => setModalOpen(true)}> Agregar</Button>
          </div>
          <div>
            {isLoading ? (
              <div className="flex justify-center w-full col-span-full">
                <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
              </div>
            ) : (
              <Card>
                <DataGrid
                  sx={{ padding: 2 }}
                  autoHeight={true}
                  columns={columns}
                  rows={spots}
                  pageSize={20}
                  getRowId={(row) => row._id}
                  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                  components={{ Toolbar: CustomToolbar }}
                />
                <NewSpotModal open={modalOpen}
                    spot={selectedSpot}
                    setSpot={setSelectedSpot}
                    onClose={() => setModalOpen(false)} />
              </Card>
            )}
          </div>
        </div>
    )
}
export default SpotsView;
