import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface Props {
  open: boolean;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  photos: string[] | null;
}

const DocumentsModal: React.FC<Props> = ({ open, onClose, photos }) => {
  return (
    <Dialog open={open} onClose={onClose} onTransitionEnd={() => !open}>
      <DialogTitle>Documentos</DialogTitle>

      {photos !== null && (
        <DialogContent>
          <div className="flex flex-col gap-2 mt-5">
            {photos.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt="photo"
                className="w-full h-full"
              />
            ))}
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};
export default DocumentsModal;
